import React from 'react'
import HubspotForm from 'react-hubspot-form'
import Layout from '../../../components/layout'

import {
	Container,
	Section,
} from '../../../components/layouts'

import {
	Subtitle,
	Paragraph,
	BlogAuthor,
	Link,
	Footer
} from '../../../components/ui'

import powerPageHeading from '../../../assets/images/powerpage_heading.png'

import theme from '../../../theme/theme'

const {
	intro,
} = theme.sections

class IndexPage extends React.Component {
	constructor (props) {
		super(props)
	}

	componentDidMount() {
		window.scrollTo(0, -1500)
	}

	render () {
		return (
			<Layout>
				<Section id="intro" bg={intro.bg} paddingTop={'100px'}>
					<Container  >
						<img alt="Turning Millennials into Donors" src={powerPageHeading} width="100%"/>

						<Subtitle fontSize={'25px'} style={{ marginTop:'1em' }}>Actionable tips and methods for nonprofits looking to reach and engage millennials as active donors </Subtitle><br/>

						<Paragraph>
          Millennials--the generation rapidly changing the way we eat, work, exercise, shop, and now, the way we give. Characterized as the generation born between 1980 and 2000, millennials today range from the ages 18 to 38. With millennials now making up the largest portion of the population, it is imperative that nonprofits begin engaging and securing them as donors. But despite making up more than a quarter of the population, millennials account for only 11% of charitable giving.
							<br/><br/>This may come as a surprise, but millennials have really good intentions. Often referred to as the purpose-driven generation, millennials care about doing social good and yearn to make an impact on the world around them. But there’s a problem--according Blackbaud’s Next Generation of American Giving study, only 25% of millennials believe that monetary giving is the best way to make a difference with charitable organizations.
							<br/><br/>So how can you, as a nonprofit, turn well-meaning, “I’m-going-to-save-the-world” millennials into tangible donors? And even better--how can you turn them into recurring donors?
          In this guide, I cover seven approaches that will help your nonprofit both involve and capture the millennial generation.

							<ul>
								<li>Chapter 1: 3 ways to get Millennials directly engaged with your organization</li>
								<li><Link onClick={this.linking} style={{ display: 'inline' }} href={'/blog/posts/MillennialsChapter2/'}>Chapter 2: Generate Social Currency (aka Millennial Candy)</Link></li>
								<li><Link onClick={this.linking} style={{ display: 'inline' }} href={'/blog/posts/MillennialsChapter3/'}>Chapter 3: 4 Methods of Transparency that Millennials Love</Link></li>
								<li><Link onClick={this.linking} style={{ display: 'inline' }} href={'/blog/posts/MillennialsChapter4/'}>Chapter 4: How To Make Your Social Media Attractive and Effective</Link></li>
								<li><Link onClick={this.linking} style={{ display: 'inline' }} href={'/blog/posts/MillennialsChapter5/'}>Chapter 5: Turn the Tables By Getting Millennials To Fundraise For You</Link></li>
								<li><Link onClick={this.linking} style={{ display: 'inline' }} href={'/blog/posts/MillennialsChapter6/'}>Chapter 6: How to get Millennials to Pull the Trigger and Donate (in 2 minutes)</Link></li>
								<li><Link onClick={this.linking} style={{ display: 'inline' }} href={'/blog/posts/MillennialsChapter7/'}>Chapter 7: Turning One-Time Donors into Lifetime Donors</Link></li>
							</ul>

							<Subtitle fontSize={'25px'} style={{ marginTop:'1em' }} id="chapter1">Chapter 1: 3 ways to get Millennials directly engaged with your organization</Subtitle>
        As mentioned in the introduction, only 25% of millennials think that monetary giving is the best way to make an impact on a charitable organization. That’s because millennials gain more of a sense of purpose when they directly participate with a cause or organization.
        By creating ways for millennials to directly interact with your organization, you help them cultivate passion for your cause. Plus, your easiest ask for support is going to be directed to the individuals who feel invested in your organization and cause--the people who consider your organization as part of their identity. And good news! Many millennials are at a point in their lives where they are searching for causes to latch onto and create their identity around. Here’s 3 ways to cultivate that type of involvement:
							<br/>
							<ol>
								<li ><strong>Campus chapters: </strong>
									<ul>
										<li>Campus chapters are a great way to get college students centered around your cause and bought into your organization at an early age. They also serve as (essentially) free promoters of and fundraisers for your organization.</li>
										<li>Starting a campus chapter program is actually very simple.
											<ol>
												<li>Put someone in your organization in charge of overseeing campus chapters (this will look different depending on the size of your organization and how widespread your chapters are).
												</li>
												<li>Create a “Campus Chapter Toolkit”. Take a look at Habitat for Humanity’s <Link style={{ display: 'inline' }} href="https://www.habitat.org/sites/default/files/Campus-Chapter-Prospective-Toolkit-2018.pdf">tool kit</Link>.</li>
												<li>Encourage students who contact you for internships and volunteer opportunities to start a chapter on their campus.</li>
											</ol>
										</li>
									</ul>
								</li>
								<li><strong>Educational workshops: </strong>
									<ul>
										<li>Educating prospective donors is critical into priming them to donate. Take a Saturday morning or a weeknight to host members of your community and educate them on the problem your organization is addressing. Provide snacks and refreshments. Don’t make it about how they can help--make it about who or what needs help. Only spend a few minutes at the end of your workshop or session on a call to action. Participants want to feel educated, not solicited.
										</li>
									</ul>
								</li>
								<li><strong>Volunteer Opportunities: </strong>
									<ul>
										<li>This almost goes without saying. Volunteer opportunities are a gold mine for millennials, many of whom are seeking a direct experience and connection with the cause your organization supports. Both campus chapters and educational workshops are great lead in to have people sign up for volunteer opportunities.
										</li>
									</ul>
								</li>
							</ol>

							<hr style={{ margin: '1em 0' }}/>

							<Subtitle fontSize={'30px'}>
							Looking for the full e-book?
							</Subtitle>
							<Paragraph
								fontSize={'21px'}
								marginRight={"2em"}
								fontWeight={'200'}>Fill out this form to receive our free guide <span style={{ fontStyle: 'italic' }}>Millennials To Donors</span></Paragraph>
							<HubspotForm
								id="reactHubspotForm"
								portalId="4829666"
								formId="1c650ab1-5fd3-4250-88c3-269180d71e88"
								loading={<div>{'Loading...'}</div>}
							/>


							<hr style={{ margin: '1em 0' }}/>
							<br/>
							<br/>
              Now I’ll turn it over to you. What do you think of this guide? Is there anything I missed or you want to see covered? Please send me your thoughts at <Link style={{ display: 'inline' }} href="mailto:laura@flourishchange.com">laura@flourishchange.com</Link>.

						</Paragraph>
						<BlogAuthor author="laura"/>
					</Container>

				</Section>
			</Layout>
		)
	}
}

export default IndexPage
